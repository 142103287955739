@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-slate-300;
}

/* Getting rid of blue outline/border mess on adorned MUI Textfield 
   actually need to be specific about text: type (DealSelect component)
*/
input[type="text"]:focus {
  border: none !important;
  --tw-ring-color: transparent;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  outline: none !important;
}
